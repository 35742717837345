import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import ReactHighlightWords from "react-highlight-words";
import {
  Button,
  Input,
  Space,
  Typography,
  Table,
  Tag,
  Card,
  Row,
  Col,
  Timeline,
} from "antd";
import {
  FilterFilled,
  SearchOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  DownOutlined,
} from "@ant-design/icons";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
const { Text, Title } = Typography;

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const WhatsappCloud = () => {
  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  // const [scrollButton, setScrollButton] = useState("down");
  const [scrollDirection, setScrollDirection] = useState("down");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: PAGE_SIZE_OPTIONS[0], // Default page size
    total: 0,
  });

  const searchInput = useRef(null);

  const containerRef = useRef(null); // Ref for the container div

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      if (container.scrollTop > 0) {
        setScrollDirection("up");
      } else {
        setScrollDirection("down");
      }
    }
  };

  const handleButtonClick = () => {
    if (scrollDirection === "up") {
      handleScrollUp();
    } else {
      handleScrollDown();
    }
  };

  const priority = {
    read: 3,
    delivered: 2,
    sent: 1,
    failed: 0,
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <ReactHighlightWords
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getTextCellRender = () => {
    return {
      render: (text) => <Text>{text}</Text>,
    };
  };

  const parseDateTime = (timestamp) => {
    if (timestamp)
      return new Date(Number(timestamp) * 1000).toLocaleString("sv");
    else return "————";
  };

  useEffect(() => {
    const fetchData = async () => {
      setShowLoader(true);
      try {
        const response = await axios.get("/api/wacloud/webhook/get", {
          params: {
            page: pagination.current,
            pageSize: pagination.pageSize,
          },
        });
        const totalPages = response.data.totalPages;
        const entries = response.data.messages.map((item) => {
          const entry = {};
          const datetime = new Date(Date.parse(item.createdAt));
          entry["timestamp"] = datetime.valueOf(); // Save timestamp
          entry["time"] = datetime.toLocaleString("sv"); // Save time as string
          entry["from"] =
            item.entry[0]?.changes[0]?.value?.metadata?.display_phone_number ||
            "";
          entry["to"] =
            item.entry[0]?.changes[0]?.value?.statuses[0]?.recipient_id || "";
          entry["type"] =
            item.entry[0]?.changes[0]?.value?.statuses[0]?.conversation?.origin
              ?.type || "";
          entry["status"] = "failed";
          entry["timeline"] = {};
          item.entry[0]?.changes[0]?.value?.statuses.forEach((status) => {
            if (priority[entry["status"]] < priority[status["status"]])
              entry["status"] = status["status"];
            entry.timeline[`${status.status}`] = status.timestamp;
          });
          entry["waId"] =
            item.entry[0]?.changes[0]?.value?.statuses[0]?.id || "";
          entry["sysId"] = item._id;
          entry["key"] = item._id;
          entry["errors"] =
            item.entry[0]?.changes[0]?.value?.statuses[0]?.errors || [];
          return entry;
        });

        // Sort data based on creation time (date and time wise)
        entries.sort((a, b) => {
          return Number(b.timestamp) - Number(a.timestamp);
        });

        setData(entries);
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalPages * pagination.pageSize,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setShowLoader(false);
      }
    };

    fetchData();
  }, [pagination.current, pagination.pageSize]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    const fetchSearchedData = async () => {
      if (searchText.trim() !== "") {
        setShowLoader(true);
        try {
          const response = await axios.get("/api/wacloud/webhook/search", {
            params: {
              key: searchText,
            },
          });
          const entries = response.data.data.map((item) => {
            const entry = {};
            const datetime = new Date(Date.parse(item.createdAt));
            entry["timestamp"] = datetime.valueOf(); // Save timestamp
            entry["time"] = datetime.toLocaleString("sv"); // Save time as string
            entry["from"] =
              item.entry[0]?.changes[0]?.value?.metadata
                ?.display_phone_number || "";
            entry["to"] =
              item.entry[0]?.changes[0]?.value?.statuses[0]?.recipient_id || "";
            entry["type"] =
              item.entry[0]?.changes[0]?.value?.statuses[0]?.conversation
                ?.origin?.type || "";
            entry["status"] = "failed";
            entry["timeline"] = {};
            item.entry[0]?.changes[0]?.value?.statuses.forEach((status) => {
              if (priority[entry["status"]] < priority[status["status"]])
                entry["status"] = status["status"];
              entry.timeline[`${status.status}`] = status.timestamp;
            });
            entry["waId"] =
              item.entry[0]?.changes[0]?.value?.statuses[0]?.id || "";
            entry["sysId"] = item._id;
            entry["key"] = item._id;
            entry["errors"] =
              item.entry[0]?.changes[0]?.value?.statuses[0]?.errors || [];
            return entry;
          });

          // Sort data based on creation time (date and time wise)
          entries.sort((a, b) => {
            return Number(b.timestamp) - Number(a.timestamp);
          });

          setSearchedData(entries);
        } catch (error) {
          console.error("Error fetching search data:", error);
        } finally {
          setShowLoader(false);
        }
      } else {
        setSearchedData([]);
      }
    };

    fetchSearchedData();
  }, [searchText]);

  const getDataSource = () => {
    if (searchText.trim() !== "") {
      return searchedData;
    } else {
      return data;
    }
  };

  // Function to handle scroll down on the container
  const handleScrollDown = () => {
    const container = document.getElementById("scrolldownoption"); // Assuming "contactList" is the ID of the container div
    if (container) {
      container.scrollTo({
        top: container.scrollTop + window.innerHeight,
        behavior: "smooth", // Smooth scroll behavior
      });
    }
  };

  // Function to handle scrolling up on the container
  const handleScrollUp = () => {
    const container = document.getElementById("scrolldownoption");
    if (container) {
      container.scrollTo({
        top: container.scrollTop - window.innerHeight,
        behavior: "smooth",
      });
    }
  };

  const columns = [
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      ...getColumnSearchProps("from"),
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      ...getColumnSearchProps("to"),
    },
    {
      title: "Creation Time",
      dataIndex: "time",
      key: "time",
      sorter: (a, b) => {
        return Number(a.timestamp) - Number(b.timestamp);
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => {
        if (a.type < b.type) {
          return -1;
        }
        if (a.type > b.type) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Last Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color;
        let x = status[0].toUpperCase() + status.slice(1);
        switch (x) {
          case "Delivered":
            color = "orange";
            break;
          case "Read":
            color = "green";
            break;
          case "Sent":
            color = "blue";
            break;
          case "Failed":
            color = "red";
            break;
          default:
            color = "gray";
        }
        return <Tag color={color}>{x}</Tag>;
      },
    },
    {
      title: "Whatsapp ID",
      dataIndex: "waId",
      key: "waId",
      ...getColumnSearchProps("waId"),
      ...getTextCellRender(),
    },
    {
      title: "System Id",
      dataIndex: "sysId",
      key: "sysId",
      ...getColumnSearchProps("sysId"),
      ...getTextCellRender(),
    },
  ];

  return (
    <div className="page-content w-100">
      <div
        className="container"
        id="scrolldownoption"
        style={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}
        ref={containerRef}
        onScroll={handleScroll}
      >
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Whatsapp Cloud</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    <form className="d-flex">
                      <input
                        className="form-control me-2"
                        type="search"
                        id="search"
                        placeholder="Search"
                        aria-label="Search"
                        value={searchText}
                        onChange={handleSearchChange}
                      />
                    </form>
                  </div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={getDataSource()} // Use getDataSource() to determine data source
                  columns={columns}
                  loading={showLoader}
                  scroll={{ y: data.length > 10 ? 500 : null }}
                  pagination={
                    searchText.trim() === "" && {
                      // Conditionally render pagination
                      total: pagination.total,
                      pageSize: pagination.pageSize,
                      current: pagination.current,
                      onChange: (page) => {
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          current: page,
                        }));
                      },
                      onShowSizeChange: (current, pageSize) => {
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          current: 1,
                          pageSize,
                        }));
                      },
                      pageSizeOptions: PAGE_SIZE_OPTIONS,
                      showQuickJumper: true,
                      showSizeChanger: true,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total}`,
                    }
                  }
                  expandable={{
                    expandedRowRender: (record) => {
                      const card1Content = () => {
                        if (record.status.toLowerCase() === "read") {
                          return (
                            <Timeline
                              mode="right"
                              items={[
                                {
                                  children: "Accepted",
                                  dot: <SyncOutlined />,
                                  color: "black",
                                  label: parseDateTime(record.timeline.sent),
                                },
                                {
                                  children: "Sent",
                                  label: parseDateTime(record.timeline.sent),
                                  dot: <CheckCircleOutlined />,
                                },
                                {
                                  children: "Delivered",
                                  label: parseDateTime(
                                    record.timeline.delivered
                                  ),
                                  dot: <CheckCircleOutlined />,
                                  color: "orange",
                                },
                                {
                                  children: "Read",
                                  label: parseDateTime(record.timeline.read),
                                  dot: <CheckCircleOutlined />,
                                  color: "green",
                                },
                              ]}
                            />
                          );
                        } else if (
                          record.status.toLowerCase() === "delivered"
                        ) {
                          return (
                            <Timeline
                              mode="right"
                              items={[
                                {
                                  children: "Accepted",
                                  dot: <SyncOutlined />,
                                  color: "black",
                                  label: parseDateTime(record.timeline.sent),
                                },
                                {
                                  children: "Sent",
                                  label: parseDateTime(record.timeline.sent),
                                  dot: <CheckCircleOutlined />,
                                },
                                {
                                  children: "Delivered",
                                  label: parseDateTime(
                                    record.timeline.delivered
                                  ),
                                  dot: <CheckCircleOutlined />,
                                  color: "orange",
                                },
                              ]}
                            />
                          );
                        } else if (record.status.toLowerCase() === "sent") {
                          return (
                            <Timeline
                              mode="right"
                              items={[
                                {
                                  children: "Accepted",
                                  dot: <SyncOutlined />,
                                  color: "black",
                                  label: parseDateTime(record.timeline.sent),
                                },
                                {
                                  children: "Sent",
                                  label: parseDateTime(record.timeline.sent),
                                  dot: <CheckCircleOutlined />,
                                },
                              ]}
                            />
                          );
                        } else if (record.status.toLowerCase() === "failed") {
                          return (
                            <>
                              {record.errors.map((error, index) => (
                                <div
                                  key={index}
                                  style={{
                                    border: "1px solid #ccc",
                                    padding: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p>
                                    <strong>Error Code:</strong>{" "}
                                    {error.code || "N/A"}
                                  </p>
                                  <p>
                                    <strong>Error Message:</strong>{" "}
                                    {error.title || "N/A"}
                                  </p>
                                  <p>
                                    <strong>Error Data:</strong>{" "}
                                    {error.error_data?.details || "N/A"}
                                  </p>
                                </div>
                              ))}
                            </>
                          );
                        }
                      };

                      return (
                        <Row>
                          <Col span={7} offset={2}>
                            <Card hoverable>{card1Content()}</Card>
                          </Col>
                          <Col span={8} offset={2}>
                            <Card title="preview Message" hoverable>
                              {" "}
                            </Card>
                          </Col>
                        </Row>
                      );
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "999",
        }}
      >
        <Button
          type="primary"
          shape="circle"
          icon={
            scrollDirection === "up" ? (
              <ArrowUpwardIcon />
            ) : (
              <ArrowDownwardIcon />
            )
          }
          size="large"
          onClick={handleButtonClick}
          style={{
            position: "fixed",
            bottom: 20,
            right: 20,
          }}
        />
      </div>
    </div>
  );
};

export default WhatsappCloud;
