import React, { useEffect, useState } from "react";
import axios from "axios";

import "../styles/campaignreports.css";

const CampaignReports = () => {
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState();

  const fetchCampaignReportsData = async () => {
    try {
      const response = await axios.get("/api/wateam/v2/api/getmessages");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setShowLoader(true);
      await fetchCampaignReportsData();
      setShowLoader(false);
    };

    fetchData();
  }, []);

  //   const getDataSource = () => {
  //     return data;
  //   };

  //   const columns = [
  //     {
  //       title: "Campaign ID",
  //       dataIndex: "campaignId",
  //       key: "campaignId",
  //     },
  //     {
  //       title: "Total Contacts",
  //       dataIndex: "total",
  //       key: "total",
  //     },
  //     {
  //       title: "Sent",
  //       dataIndex: "sent",
  //       key: "sent",
  //     },
  //     {
  //       title: "% of Sent",
  //       dataIndex: "sentPercentage",
  //       key: "sentPercentage",
  //     },
  //     {
  //       title: "Delivered",
  //       dataIndex: "delivered",
  //       key: "delivered",
  //     },
  //     {
  //       title: "% of Delivered",
  //       dataIndex: "deliveredPercentage",
  //       key: "deliveredPercentage",
  //     },
  //     {
  //       title: "Read",
  //       dataIndex: "read",
  //       key: "read",
  //     },
  //     {
  //       title: "% of Read",
  //       dataIndex: "readPercentage",
  //       key: "readPercentage",
  //     },
  //     {
  //       title: "Unsent",
  //       dataIndex: "unsent",
  //       key: "unsent",
  //     },
  //     {
  //       title: "% of Unsent",
  //       dataIndex: "unsentPercentage",
  //       key: "unsentPercentage",
  //     },
  //     {
  //       title: "Failed",
  //       dataIndex: "failed",
  //       key: "failed",
  //     },
  //     {
  //       title: "% of Failed",
  //       dataIndex: "failedPercentage",
  //       key: "failedPercentage",
  //     },
  //   ];

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Campaign Reports</h4>
            </div>
          </div>
        </div>
        {/* Cards */}
        <div class="container my-3">
          <h6>Quick reports</h6>
          <div class="card-container my-3">
            <div class="campcard all">
              <div class="icon">🔔</div>
              <div>
                <h6>All</h6>
                <div className="textpercentage">
                  <div class="percentage">100%</div>
                  {/* <div class="count">(307)</div> */}
                  <div class="count">({data.total})</div>
                </div>
              </div>
            </div>
            <div class="campcard sent">
              <div class="icon">✔️</div>
              <div>
                <h6>Sent</h6>
                <div className="textpercentage">
                  {/* <div class="percentage">85%</div> */}
                  {/* <div class="count">(261)</div> */}
                  <div class="percentage">{data.sentPercentage}</div>
                  <div class="count">({data.sent})</div>
                </div>
              </div>
            </div>
            <div class="campcard delivered">
              <div class="icon">
                <span className="tick1">✔</span>
                <span className="tick2">✔</span>
              </div>
              <div>
                <h6>Delivered</h6>
                <div className="textpercentage">
                  {/* <div class="percentage">80%</div> */}
                  {/* <div class="count">(246)</div> */}
                  <div class="percentage">{data.deliveredPercentage}</div>
                  <div class="count">({data.delivered})</div>
                </div>
              </div>
            </div>
            <div class="campcard read">
              <div class="icon">✔</div>
              <div>
                <h6>Read</h6>
                <div className="textpercentage">
                  {/* <div class="percentage">56%</div> */}
                  {/* <div class="count">(172)</div> */}
                  <div class="percentage">{data.readPercentage}</div>
                  <div class="count">({data.read})</div>
                </div>
              </div>
            </div>

            {/* <div class="campcard replied">
              <h6>Replied</h6>
              <div class="icon">↩️</div>
              <div class="percentage">2%</div>
              <div class="count">(7)</div>
            </div> */}

            <div class="campcard failed">
              <div class="icon">❌</div>
              <div>
                <h6>Failed</h6>
                <div className="textpercentage">
                  {/* <div class="percentage">14%</div> */}
                  {/* <div class="count">(46)</div> */}
                  <div class="percentage">{data.failedPercentage}</div>
                  <div class="count">({data.failed})</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignReports;
