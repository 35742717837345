import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import ReactHighlightWords from "react-highlight-words";
import {
  Button,
  Input,
  Space,
  Typography,
  Table,
  Tag,
  Card,
  Row,
  Col,
  Timeline,
} from "antd";
import {
  FilterFilled,
  SearchOutlined,
  CheckCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const { Text, Title } = Typography;

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const ApiResponseError = () => {
  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchQueueId, setSearchQueueId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("down");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: PAGE_SIZE_OPTIONS[0], // Default page size
    total: 0,
  });
  const searchInput = useRef(null);

  const containerRef = useRef(null);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      if (container.scrollTop > 0) {
        setScrollDirection("up");
      } else {
        setScrollDirection("down");
      }
    }
  };

  const handleButtonClick = () => {
    if (scrollDirection === "up") {
      handleScrollUp();
    } else {
      handleScrollDown();
    }
  };

  const priority = {
    read: 3,
    delivered: 2,
    sent: 1,
    failed: 0,
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchQueueId(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <ReactHighlightWords
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getTextCellRender = () => {
    return {
      render: (text) => <Text>{text}</Text>,
    };
  };

  const parseDateTime = (timestamp) => {
    if (timestamp)
      return new Date(Number(timestamp) * 1000).toLocaleString("sv");
    else return "————";
  };
  // Get the data via GET API
  useEffect(() => {
    const fetchData = async () => {
      setShowLoader(true);
      try {
        const response = await axios.get("/api/wateam/v2/api/getapi", {
          params: {
            page: pagination.current,
            pageSize: pagination.pageSize,
          },
        });
        console.log(response.data.messages);
        const totalPages = response.data.totalPages;
        const entries = response.data.messages.map((item) => {
          const entry = {};
          entry["id"] = item._id;
          entry["queueId"] = item.message.queue_id;
          entry["status"] = item.message.message_status;
          entry["createdAt"] = new Date(item.createdAt).toLocaleString();
          entry["response"] = JSON.stringify(item.response, null, 2);
          return entry;
        });

        // entries.sort((a, b) => {
        //   return Number(b.timestamp) - Number(a.timestamp);
        // });
        console.log(entries);

        setData(entries);
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: totalPages * pagination.pageSize,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setShowLoader(false);
      }
    };

    fetchData();
  }, [pagination.current, pagination.pageSize]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  //Search Functionality
  useEffect(() => {
    const fetchSearchedData = async () => {
      if (searchText.trim() !== "") {
        setShowLoader(true);
        try {
          const response = await axios.get("/api/wateam/v2/api/search", {
            params: {
              key: searchText,
            },
          });

          const entries = response.data.messages.map((item) => {
            const entry = {};
            const datetime = new Date(
              Date.parse(item.webhookResponse?.createdAt)
            );
            entry["timestamp"] = datetime.valueOf();
            entry["time"] = datetime.toLocaleString("sv");
            entry["from"] =
              item.webhookResponse?.entry[0]?.changes[0]?.value?.metadata
                ?.display_phone_number || "";
            entry["to"] =
              item.webhookResponse?.entry[0]?.changes[0]?.value?.statuses[0]
                ?.recipient_id || "";
            entry["type"] =
              item.webhookResponse?.entry[0]?.changes[0]?.value?.statuses[0]
                ?.conversation?.origin?.type || "";
            entry["status"] = "failed";
            entry["timeline"] = {};
            item.webhookResponse?.entry[0]?.changes[0]?.value?.statuses.forEach(
              (status) => {
                if (priority[entry["status"]] < priority[status["status"]])
                  entry["status"] = status["status"];
                entry.timeline[`${status.status}`] = status.timestamp;
              }
            );
            entry["waId"] =
              item.webhookResponse?.entry[0]?.changes[0]?.value?.statuses[0]
                ?.id || "";
            // entry["sysId"] = item.webhookResponse._id || "";
            // entry["key"] = item.webhookResponse._id || item.apiResponse?._id;
            entry["queueId"] = item.apiResponse?.message?.queue_id || "";
            entry["errors"] =
              item.webhookResponse?.entry[0]?.changes[0]?.value?.statuses[0]
                ?.errors || [];
            return entry;
          });

          console.log(entries);
          entries.sort((a, b) => {
            return Number(b.timestamp) - Number(a.timestamp);
          });

          setSearchedData(entries);
        } catch (error) {
          console.error("Error fetching search data:", error);
        } finally {
          setShowLoader(false);
        }
      } else {
        setSearchedData([]);
      }
    };

    fetchSearchedData();
  }, [searchText]);

  const handleSearchQueueId = (e) => {
    setSearchQueueId(e.target.value);
  };

  //Search Functionality
   useEffect(() => {
    const fetchSearchedQueueId = async () => {
      if (searchQueueId.trim() !== "") {
        setShowLoader(true);
        try {
          const response = await axios.get(
            "/api/wateam/v2/api/queueidapiresponse",
            {
              params: {
                queue_id: searchQueueId,
              },
            }
          );
  
        
          const messageData = response.data.message;
  
          const entry = {
            id: messageData._id,
            queueId: messageData.message.queue_id,
            status: messageData.message.message_status,
            createdAt: new Date(messageData.createdAt).toLocaleString(),
            response: JSON.stringify(messageData.response, null, 2),
          };
  
          console.log(entry);
          setSearchedData([entry]); 
        } catch (error) {
          console.error("Error fetching search data:", error);
        } finally {
          setShowLoader(false);
        }
      } else {
        setSearchedData([]);
      }
    };
  
    fetchSearchedQueueId();
  }, [searchQueueId]);

  const getDataSource = () => {
    if (searchText.trim() !== "" || searchQueueId.trim() !== "") {
      return searchedData;
    } else {
      return data;
    }
  };

  // Function to handle scroll down on the container
  const handleScrollDown = () => {
    const container = document.getElementById("scrolldownoption"); // Assuming "contactList" is the ID of the container div
    if (container) {
      container.scrollTo({
        top: container.scrollTop + window.innerHeight,
        behavior: "smooth", // Smooth scroll behavior
      });
    }
  };

  // Function to handle scrolling up on the container
  const handleScrollUp = () => {
    const container = document.getElementById("scrolldownoption");
    if (container) {
      container.scrollTo({
        top: container.scrollTop - window.innerHeight,
        behavior: "smooth",
      });
    }
  };

  const columns = [
    {
      title: "Creation Time",
      dataIndex: "createdAt",
      key: "createdAt",
      // ellipsis: true,
      // sorter: (a, b) => {
      //   return Number(a.timestamp) - Number(b.timestamp);
      // },
    },

    {
      title: "Queue Id",
      dataIndex: "queueId",
      key: "queueId",
      ...getColumnSearchProps("queueId"),
      ...getTextCellRender(),
    },
    {
      title: "Response",
      dataIndex: "response",
      key: "response",
      ...getColumnSearchProps("response"),
      ...getTextCellRender(),
    },
  ];

  return (
    <div className="page-content w-100">
      <div
        className="container"
        id="scrolldownoption"
        style={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}
        ref={containerRef}
        onScroll={handleScroll}
      >
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">API Response Error V2</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
{/*                     <form className="d-flex">
                      <input
                        className="form-control me-2"
                        type="search"
                        id="search"
                        placeholder="Search"
                        aria-label="Search"
                        value={searchText}
                        onChange={handleSearchChange}
                        // onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </form> */}
                    <form className="d-flex">
                      <input
                        className="form-control me-2"
                        type="search"
                        id="queueid"
                        placeholder="Queue Id"
                        aria-label="Queue Id"
                        value={searchQueueId}
                        onChange={handleSearchQueueId}
                        // onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </form>
                  </div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={getDataSource()}
                  columns={columns}
                  loading={showLoader}
                  scroll={{ y: data.length > 5 ? 500 : null }}
                  pagination={
                    searchText.trim() === "" && {
                      // Conditionally render pagination
                      total: pagination.total,
                      pageSize: pagination.pageSize,
                      current: pagination.current,
                      onChange: (page) => {
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          current: page,
                        }));
                      },
                      onShowSizeChange: (current, pageSize) => {
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          current: 1,
                          pageSize,
                        }));
                      },
                      pageSizeOptions: PAGE_SIZE_OPTIONS,
                      showQuickJumper: true,
                      showSizeChanger: true,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total}`,
                    }
                  }
                  // expandable={{
                  //   expandedRowRender: (record) => (
                  //     <Card>
                  //       <Row>
                  //         <Col span={24}>
                  //           <Title level={5} style={{ marginBottom: 0 }}>
                  //             API Response Error Timeline
                  //           </Title>
                  //           <Timeline>
                  //             <Timeline.Item>
                  //               <Text strong>Messaging Product:</Text>{" "}
                  //               {record.response?.messaging_product || "N/A"}
                  //             </Timeline.Item>
                  //             <Timeline.Item>
                  //               <Text strong>Contacts:</Text>
                  //               {Array.isArray(record.response?.contacts) &&
                  //               record.response.contacts.length > 0 ? (
                  //                 record.response.contacts.map(
                  //                   (contact, index) => (
                  //                     <div key={index}>
                  //                       <Text>
                  //                         Input: {contact.input || "N/A"}
                  //                       </Text>
                  //                       ,{" "}
                  //                       <Text>
                  //                         WA ID: {contact.wa_id || "N/A"}
                  //                       </Text>
                  //                     </div>
                  //                   )
                  //                 )
                  //               ) : (
                  //                 <Text>No contacts available</Text>
                  //               )}
                  //             </Timeline.Item>
                  //             <Timeline.Item>
                  //               <Text strong>Messages:</Text>
                  //               {Array.isArray(record.response?.messages) &&
                  //               record.response.messages.length > 0 ? (
                  //                 record.response.messages.map(
                  //                   (message, index) => (
                  //                     <div key={index}>
                  //                       <Text>ID: {message.id || "N/A"}</Text>,{" "}
                  //                       <Text>
                  //                         Status:{" "}
                  //                         {message.message_status || "N/A"}
                  //                       </Text>
                  //                     </div>
                  //                   )
                  //                 )
                  //               ) : (
                  //                 <Text>No messages available</Text>
                  //               )}
                  //             </Timeline.Item>
                  //           </Timeline>
                  //         </Col>
                  //       </Row>
                  //     </Card>
                  //   ),
                  // }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "999",
        }}
      >
        <Button
          type="primary"
          shape="circle"
          icon={
            scrollDirection === "up" ? (
              <ArrowUpwardIcon />
            ) : (
              <ArrowDownwardIcon />
            )
          }
          size="large"
          onClick={handleButtonClick}
          style={{
            position: "fixed",
            bottom: 20,
            right: 20,
          }}
        />
      </div>
    </div>
  );
};

export default ApiResponseError;
