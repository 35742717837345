import React from "react";
import "../styles/dashboard.css";

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <h1>
        <span style={{ color: "#0D6EFD" }}>
          This Dashboard is for <br /> WABA Reports only
        </span>
      </h1>
      <h2
        style={{
          color: "rgb(211, 32, 32)",
        }}
        className="blink-text"
      >
        See Reports...
      </h2>
    </div>
  );
};

export default Dashboard;
